import { useAuth } from "@clerk/nextjs";
import classNames from "classnames";
import { ButtonVariant, CoButton } from "components/CoButton";
import UserButton from "components/TopBar/UserButton";
import { HELP_CENTER_URL } from "constants/config";
import path from "constants/path";
import { FeatureFlag, useIsFeatureEnabled } from "features/FeatureGates";
import NewSearchButton from "features/History/components/NewSearchButton";
import NewThreadButton from "features/NewThreadButton";
import { searchController } from "helpers/api";
import { useAppDispatch } from "hooks/useStore";
import Link from "next/link";
import React from "react";
import { setIsSearching } from "store/slices/search";

// SidebarButtonBase component with shared styles
type SidebarButtonBaseProps = {
  isSelected: boolean;
  iconClass: string;
  className?: string;
};

const SidebarButtonBase = ({
  isSelected,
  iconClass,
  className,
}: SidebarButtonBaseProps) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center px-4 py-3 hover:bg-bgr-faint w-full",
        isSelected
          ? "border-l-4 border-l-fg-base"
          : "border-l-4 border-l-transparent",
        className
      )}
    >
      <i
        className={classNames(
          `text-2xl ${iconClass}`,
          isSelected ? "text-fg-base" : "text-fg-muted"
        )}
      />
    </div>
  );
};

// SidebarNavButton for navigation with Next.js Link
type SidebarNavButtonProps = {
  href: string;
  isSelected: boolean;
  iconClass: string;
};

const SidebarNavButton = ({
  href,
  isSelected,
  iconClass,
}: SidebarNavButtonProps) => {
  return (
    <Link
      href={href}
      onClick={(e) => {
        // Prevent default navigation if already on this page
        if (window.location.pathname == href) {
          e.preventDefault();
        }
      }}
      className="w-full"
    >
      <SidebarButtonBase isSelected={isSelected} iconClass={iconClass} />
    </Link>
  );
};

type SidebarCollapsedContentProps = {
  onExpand: () => void;
};

const SidebarCollapsedContent = ({
  onExpand,
}: SidebarCollapsedContentProps) => {
  const dispatch = useAppDispatch();

  const handleClickHome = () => {
    searchController.abort();
    dispatch(setIsSearching(false));
  };

  const { isSignedIn, isLoaded } = useAuth();

  const isThreadsEnabled = useIsFeatureEnabled(FeatureFlag.THREADS);

  return (
    <div className="flex flex-col items-stretch w-full h-full text-fg-base">
      <div className="sticky top-0 flex flex-col items-center">
        <Link
          href={path.SEARCH}
          onClick={(e) => {
            if (window.location.pathname === path.SEARCH) {
              e.preventDefault();
            }
            handleClickHome();
          }}
          className="flex items-center justify-center h-[72px]"
        >
          <img
            alt="Consensus"
            className="w-[26px] h-[28px]"
            src="/icons/logo-min.svg"
          />
        </Link>
        {isSignedIn && isLoaded && (
          <>
            {isThreadsEnabled ? (
              <div className="mb-4">
                <NewThreadButton isCollapsed />
              </div>
            ) : (
              <div className="mb-4">
                <NewSearchButton isCollapsed />
              </div>
            )}
            <SidebarNavButton
              href="/search"
              isSelected={window.location.pathname == path.SEARCH + "/"}
              iconClass="icon-home-converted"
            />
            <SidebarNavButton
              href="/hub"
              isSelected={window.location.pathname.includes("/hub")}
              iconClass="icon-folder-converted"
            />
          </>
        )}
      </div>
      <div className="flex-1"></div>
      <div className="sticky bottom-0 left-0 flex flex-col items-center w-full pb-5">
        <a
          className="flex items-center justify-center px-4 py-3 rounded-lg hover:bg-bgr-faint"
          href={HELP_CENTER_URL}
          target="_blank"
        >
          <i className="text-2xl icon-circle-help text-fg-muted" />
        </a>
        {isSignedIn && (
          <div className="flex justify-center w-full my-3">
            <UserButton offset={[5, 25]} placement="right-end" />
          </div>
        )}
        <div className="my-3 border-b border-border-base"></div>
        <div className="flex justify-center w-full">
          <CoButton
            data-testid="open-sidebar-button"
            variant={ButtonVariant.Tertiary}
            onClick={onExpand}
            icon="icon-chevron-right"
          />
        </div>
      </div>
    </div>
  );
};

export default SidebarCollapsedContent;
