import classNames from "classnames";
import { BOOKMARK_LISTNAME_FAVORITE } from "constants/config";
import { useBookmarks } from "features/Bookmarks";
import { FeatureFlag, useIsFeatureEnabled } from "features/FeatureGates";
import { getBookmarkCountsByType, IBookmarkListItem } from "helpers/bookmark";
import pluralize from "helpers/pluralize";
import useLabels from "hooks/useLabels";
import React, { ReactNode } from "react";

type BookmarkListItemProps = {
  bookmarkList: IBookmarkListItem;
  children?: ReactNode;
  onListItemClicked?: () => void;
};

/**
 * @component BookmarkListItem
 * @description a card displaying a bookmark list
 * @example
 * return (
 *   <BookmarkListItem
 *      bookmarkList={bookmarkList}
 *      onClickEditList={() => {
 *        handleClickEditList(bookmarkList.id);
 *      }}
 *      onClickDeleteList={() => {
 *        handleClickDeleteList(bookmarkList.id);
 *      }}
 *    />
 * )
 */
const BookmarkListItem = ({
  bookmarkList,
  children,
  onListItemClicked = () => {},
}: BookmarkListItemProps) => {
  const [labels] = useLabels("features.bookmark-lists");
  const isThreadsEnabled = useIsFeatureEnabled(FeatureFlag.THREADS);

  const { bookmarkItems } = useBookmarks();
  const { paperBookmarkCount, searchBookmarkCount, threadBookmarkCount } =
    getBookmarkCountsByType(bookmarkList.id, bookmarkItems);
  const isFavorite = bookmarkList.text_label == BOOKMARK_LISTNAME_FAVORITE;

  return (
    <button
      onClick={onListItemClicked}
      className="flex items-center w-full gap-3 p-3 text-left bg-white border rounded-xl min-h-[68px] cursor-pointer"
    >
      <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-bgr-subtle">
        <i
          className={classNames(
            "text-xl text-fg-muted ",
            isFavorite ? "icon-bookmark" : "icon-file"
          )}
        />
      </div>
      <div className="flex flex-col flex-1">
        <h2 className="sm-md">{bookmarkList.text_label}</h2>
        <p className="tiny-normal text-fg-muted">
          {pluralize(labels["bookmark-count-summary"], {
            PAPER_COUNT: paperBookmarkCount,
            SEARCH_COUNT: isThreadsEnabled ? 0 : searchBookmarkCount,
            THREAD_COUNT: isThreadsEnabled
              ? threadBookmarkCount + searchBookmarkCount // Search bookmarks appear as threads here because they will be automatically converted to a thread when clicked.
              : 0,
          }).replace(/^ · | · $/g, "")}
        </p>
      </div>
      {children}
    </button>
  );
};

export default BookmarkListItem;
