import {
  BookmarkType,
  findBookmarkItem,
  IBookmarkItem,
  IBookmarkListItem,
} from "helpers/bookmark";
import { useAppDispatch } from "hooks/useStore";
import { setIsSaveBookmarkModalOpen } from "store/slices/bookmark";
import useBookmarks from "./useBookmarks";

/**
 * @hook useSaveBookmarkModal
 * @description Manages opening the save bookmark modal. Encapsulates the logic for setting all of the necessary redux state for bookmarks of different types used by the modal as you open it.
 */
const useSaveBookmarkModal = () => {
  const dispatch = useAppDispatch();

  const {
    addListTargetForNewBookmarkItem,
    bookmarkItems,
    bookmarkLists,
    clearListTargetsForNewBookmarkItem,
    isBookmarkItemsLoaded,
    isBookmarkListLoaded,
    setSaveBookmarkState,
  } = useBookmarks();

  /**
   * Generalized function to open the save bookmark modal for any bookmark type.
   */
  const openSaveBookmarkModal = (
    bookmarkType: BookmarkType,
    idValue: string | number
  ) => {
    if (!isBookmarkItemsLoaded || !isBookmarkListLoaded) return;

    const saveBookmarkState = {
      bookmarkType,
      paperId:
        bookmarkType === BookmarkType.PAPER ? String(idValue) : undefined,
      searchUrl:
        bookmarkType === BookmarkType.SEARCH ? String(idValue) : undefined,
      threadId:
        bookmarkType === BookmarkType.THREAD ? String(idValue) : undefined,
      uploadedPaperId:
        bookmarkType === BookmarkType.UPLOADED_PAPER
          ? String(idValue)
          : undefined,
    };

    setSaveBookmarkState(saveBookmarkState);
    clearListTargetsForNewBookmarkItem();

    bookmarkLists.forEach((bookmarkList: IBookmarkListItem) => {
      const item: IBookmarkItem | null = findBookmarkItem(
        saveBookmarkState,
        bookmarkList.id,
        bookmarkItems
      );
      if (item) {
        addListTargetForNewBookmarkItem(bookmarkList.id);
      }
    });

    dispatch(setIsSaveBookmarkModalOpen(true));
  };

  return {
    openSaveBookmarkModalForPaper: (paperId: string) =>
      openSaveBookmarkModal(BookmarkType.PAPER, paperId),
    openSaveBookmarkModalForSearch: (searchUrl: string) =>
      openSaveBookmarkModal(BookmarkType.SEARCH, searchUrl),
    openSaveBookmarkModalForThread: (threadId: string) =>
      openSaveBookmarkModal(BookmarkType.THREAD, threadId),
    openSaveBookmarkModalForUploadedPaper: (paperId: string) =>
      openSaveBookmarkModal(BookmarkType.UPLOADED_PAPER, paperId),
  };
};

export default useSaveBookmarkModal;
