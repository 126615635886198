import classNames from "classnames";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import { HistoryItem as HistoryItemType, HistoryType } from "../types";
import HistoryItem from "./HistoryItem";

type HistoryGroupProps = {
  histories: HistoryItemType[];
  label: string;
  onClick: (history: HistoryItemType) => void;
  onDelete: (history: HistoryItemType) => void;
  onSave: (history: HistoryItemType) => void;
};

function HistoryGroup({
  histories,
  label,
  onClick,
  onDelete,
  onSave,
}: HistoryGroupProps) {
  const { isIncognitoModeActive } = useIncognitoMode();

  if (histories.length === 0) {
    return null;
  }

  return (
    <div className="w-full overflow-hidden">
      <h2
        className={classNames(
          "pl-4 mb-2 mt-4 tiny-md text-fg-muted",
          isIncognitoModeActive && "opacity-50"
        )}
      >
        {label}
      </h2>
      <ol className="w-full overflow-hidden">
        {histories.map((history) => (
          <HistoryItem
            key={
              history.type === HistoryType.Thread
                ? history.thread_id
                : history.id
            }
            onDelete={onDelete}
            onClick={onClick}
            onSave={onSave}
            history={history}
          />
        ))}
      </ol>
    </div>
  );
}

export default HistoryGroup;
