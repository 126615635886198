import { useAuth } from "@clerk/nextjs";
import Popover from "components/Popover";
import { usePremiumFeatureModal } from "components/Subscription";
import Switch from "components/Switch/Switch";
import Tooltip from "components/Tooltip";
import { FeatureFlag, useIsFeatureEnabled } from "features/FeatureGates";
import useCredits from "hooks/useCredits";
import useLabels from "hooks/useLabels";
import useProToggle from "hooks/useProToggle";
import React, { useState } from "react";
import { CreditedFeature } from "store/slices/subscription";
import ProToggleTooltip from "./ProToggleTooltip";

interface ProToggleProps {
  isLoading?: boolean;
}

function ProToggle({ isLoading }: ProToggleProps) {
  const [proToggleLabels] = useLabels("pro-toggle");
  const [tooltipInstance, setTooltipInstance] = useState<any>(null);

  const { openPremiumFeatureModal } = usePremiumFeatureModal();
  const handleClickUpgradePremium = async () => {
    openPremiumFeatureModal(CreditedFeature.PRO_ANALYSIS);
    tooltipInstance?.hide();
  };

  const {
    isProToggleOn,
    changeProToggle,
    isNotEnoughResults,
    isNuanceRequired,
    canEnableProToggle,
  } = useProToggle();

  const isThreadsEnabled = useIsFeatureEnabled(FeatureFlag.THREADS);
  const { isPremium, isOutOfProAnalysisCredits } = useCredits();
  const { isSignedIn, isLoaded } = useAuth();

  // Always allow the switch to be turned back off, even when the pro feature should be disabled.
  const isSwitchDisabled = isLoading || (!isProToggleOn && !canEnableProToggle);

  const showPaywallOnToggle =
    isThreadsEnabled &&
    isOutOfProAnalysisCredits &&
    !isProToggleOn &&
    !isPremium &&
    isSignedIn &&
    isLoaded;

  const handleToggleProAnalysis = (state: boolean) => {
    if (showPaywallOnToggle) {
      openPremiumFeatureModal(CreditedFeature.PRO_ANALYSIS, true);
    }
    changeProToggle(state);
  };

  const switchMarkup = (
    <div className="flex items-center gap-x-1.5">
      <Switch
        disabled={isSwitchDisabled}
        active={isProToggleOn}
        onChange={handleToggleProAnalysis}
      />
      <span className="text-fg-base sm-md">{proToggleLabels["pro"]}</span>
    </div>
  );

  if (isPremium) {
    return (
      <Tooltip
        content={proToggleLabels["enable-pro-analysis"]}
        placement="bottom"
      >
        {switchMarkup}
      </Tooltip>
    );
  }

  return (
    <div
      data-intercom-target="Pro toggle"
      className="flex items-center gap-x-2"
    >
      <Popover
        disabled={isLoading}
        interactive={canEnableProToggle}
        touch={false}
        maxWidth={340}
        onShown={(instance: any) => {
          setTooltipInstance(instance);
        }}
        onHidden={(instance: any) => {
          setTooltipInstance(instance);
        }}
        tooltipContent={
          <ProToggleTooltip
            isNotEnoughResults={isNotEnoughResults}
            isNuanceRequired={isNuanceRequired}
            onClickUpgradeToPremium={handleClickUpgradePremium}
          />
        }
      >
        {switchMarkup}
      </Popover>
    </div>
  );
}

export default ProToggle;
