import classNames from "classnames";
import Icon from "components/Icon";
import Popover from "components/Popover";
import { useAppSelector } from "hooks/useStore";
import Link from "next/link";
import { MouseEventHandler, useMemo, useState } from "react";
import { HistoryItem as HistoryItemType, HistoryType } from "../types";
import { getFilterCountForSearchHistory } from "../utils/histories";
import HistoryActionsModal from "./HistoryActionsModal";

type HistoryItemProps = {
  history: HistoryItemType;
  onClick: (history: HistoryItemType) => void;
  onDelete: (history: HistoryItemType) => void;
  onSave: (history: HistoryItemType) => void;
};

export const isUploadedPaperUrl = (url: string) => {
  return url.includes("papers/uploaded/") || url.includes("papers/u/");
};

function HistoryItem({ history, onClick, onDelete, onSave }: HistoryItemProps) {
  const isMobile = useAppSelector((state) => state.setting.isMobile);
  const [isSelected, setIsSelected] = useState(false);

  const filterCount = useMemo(() => {
    if (history.type === HistoryType.Search)
      return getFilterCountForSearchHistory(history);
    return 0;
  }, [history]);

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (isMobile && !isSelected) {
      // Disable the link and toggle selected state upon first click on mobile
      e.preventDefault();
      setIsSelected(true);
    } else {
      // Allow the link to proceed
      onClick(history);
    }
  };

  const isUploadedPaperItem = history.type === HistoryType.UploadedPaper;

  const id =
    history.type === HistoryType.Thread ? history.thread_id : history.id;
  const title =
    history.type === HistoryType.Thread ? history.title : history.query;

  return (
    <li key={id} className="relative w-full overflow-hidden group">
      <div
        className={classNames(
          "flex mx-3 gap-2 rounded-lg items-center",
          isSelected && "bg-gray-100"
        )}
      >
        <Link href={history.link} passHref legacyBehavior>
          <a
            onClick={handleOnClick}
            className="flex flex-1 p-2 text-fg-base sm-normal"
          >
            <span
              className={classNames(
                "flex-1 line-clamp-1 leading-[175%] pr-2 text-ellipsis",
                !isMobile ? "group-hover:pr-0" : isSelected && "pr-0"
              )}
            >
              {title}
            </span>
            {isUploadedPaperItem && (
              <i
                className={classNames(
                  "icon-file-arrow-up text-fg-muted",
                  !isMobile && !isUploadedPaperItem
                    ? "group-hover:hidden"
                    : !isSelected && "block"
                )}
              />
            )}
            {filterCount > 0 && (
              <div
                className={classNames(
                  "flex items-center",
                  !isMobile
                    ? "group-hover:hidden group-hover:pl-2"
                    : isSelected && "mr-0 pl-2"
                )}
              >
                <span className="flex items-center gap-[3px] px-1 text-[10px] text-white rounded-full bg-gray-550">
                  <Icon
                    className="w-[10px] h-[10px] text-white fill-current stroke-current stroke-0"
                    name="filter"
                    local
                  />
                  {filterCount}
                </span>
              </div>
            )}
          </a>
        </Link>
        <div>
          {!isUploadedPaperItem && (
            <Popover
              interactive
              placement="right"
              tooltipContent={
                <HistoryActionsModal
                  onDelete={() => onDelete(history)}
                  onSave={() => onSave(history)}
                />
              }
              trigger="click"
            >
              <button
                className={classNames(
                  "px-2 mr-2",
                  !isMobile
                    ? "hidden group-hover:block"
                    : isSelected
                    ? "block"
                    : "hidden"
                )}
              >
                <i
                  className="icon-ellipsis-vertical text-fg-muted"
                  data-testid="ellipsis-button"
                />
              </button>
            </Popover>
          )}
        </div>
      </div>
    </li>
  );
}

export default HistoryItem;
