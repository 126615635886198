import {
  applyFiltersToQuery,
  getFiltersFromQuery,
} from "features/FilterDrawer/utils/queryParams/filterQueryParams";
import { SearchHistory, ThreadHistory } from "helpers/api";
import {
  parseUrlToQuery,
  ResultPageParams,
  resultsPagePath,
  threadPath,
} from "helpers/pageUrl";
import { SearchFilterProperties } from "helpers/services/mixpanel/events";
import { ParsedUrlQuery } from "querystring";
import { getFiltersFromParams } from "../../../helpers/search";
import { isUploadedPaperUrl } from "../components/HistoryItem";
import { HistoryItem, HistoryType } from "../types";

export type SearchHistoryGroups = {
  today: SearchHistory[];
  lastSevenDays: SearchHistory[];
  lastThirtyDays: SearchHistory[];
  lastYear: SearchHistory[];
  count: number;
};

export function groupHistoriesByDate(
  searchHistories: SearchHistory[],
  threads: ThreadHistory[],
  query?: ParsedUrlQuery
): {
  today: HistoryItem[];
  lastSevenDays: HistoryItem[];
  lastThirtyDays: HistoryItem[];
  lastYear: HistoryItem[];
  count: number;
} {
  const groups = {
    today: [] as HistoryItem[],
    lastSevenDays: [] as HistoryItem[],
    lastThirtyDays: [] as HistoryItem[],
    lastYear: [] as HistoryItem[],
    count: 0,
  };

  const now = new Date();

  const taggedHistories: HistoryItem[] = [
    ...threads.map((t) => ({
      ...t,
      type: HistoryType.Thread as const,
      link: threadPath(t.thread_id, t.title),
    })),
    ...searchHistories.map((s) => {
      if (isUploadedPaperUrl(s.full_url)) {
        return {
          ...s,
          type: HistoryType.UploadedPaper as const,
          link: s.full_url,
        };
      } else {
        return {
          ...s,
          type: HistoryType.Search as const,
          link: getSearchPathFromSearchHistory(s, query),
        };
      }
    }),
  ];

  taggedHistories.forEach((history) => {
    const date =
      history.type === HistoryType.Thread
        ? new Date(history.created_at)
        : new Date(history.last_searched_at);

    const diffTime = now.getTime() - date.getTime();
    if (diffTime < 0) return;

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 1) {
      groups.today.push(history);
    } else if (diffDays <= 7) {
      groups.lastSevenDays.push(history);
    } else if (diffDays <= 30) {
      groups.lastThirtyDays.push(history);
    } else {
      groups.lastYear.push(history);
    }
  });

  groups.count = searchHistories.length + threads.length;

  return groups;
}

// Given the URL to the backend search API, parse out the query and filter parameters in order to construct the equivalent frontend search URL.
//
// Example full_url: http://python-backend-url:8080/paper_search/?query=write%205%20words%20about%20vaccines&page=0&size=10&open_access=true&cite_min=10
export function getSearchPathFromSearchHistory(
  searchHistory: SearchHistory,
  extraParams: ResultPageParams = {}
): string {
  // Maintain current parameters not set by the search history, such as pro
  // or testing params
  let searchHistoryParams = parseUrlToQuery(searchHistory.full_url);

  if (searchHistoryParams.open_access === "true") {
    // Special case: Annoyingly, the open access filter is "oa" in the browser URL, but "open_access" in the API, which searchHistory stores. Account for that here.
    searchHistoryParams.oa = "true";
    delete searchHistoryParams.open_access;
  }

  let combinedParams = applyFiltersToQuery(
    extraParams as ParsedUrlQuery,
    getFiltersFromQuery(searchHistoryParams)
  );

  return resultsPagePath(searchHistory.query, combinedParams);
}

export function getSearchHistoryFilters(
  searchHistory: SearchHistory
): SearchFilterProperties {
  // Parsing the URL to get the query parameters and handle for situations where the URL is relative
  const urlObj = new URL(
    searchHistory.full_url.startsWith("http")
      ? searchHistory.full_url
      : `${window.location.origin}${searchHistory.full_url}`
  );
  const queryParams = urlObj.searchParams;

  // Map the query parameters to an object
  const queryObj: { [key: string]: any } = {};

  queryParams.forEach((value, key) => {
    if (value === "true" || value === "false") {
      queryObj[key] = value === "true";
    } else if (!isNaN(Number(value))) {
      queryObj[key] = Number(value);
    } else {
      queryObj[key] = value;
    }
  });

  return getFiltersFromParams(queryObj);
}

export function getFilterCountForSearchHistory(
  searchHistory: SearchHistory
): number {
  const filters = getSearchHistoryFilters(searchHistory) as {
    [key: string]: any;
  };

  return Object.keys(filters).filter((key) => filters[key] !== undefined)
    .length;
}
