import classNames from "classnames";
import path from "constants/path";
import useLabels from "hooks/useLabels";
import { useRouter } from "next/router";

export enum NavigationTab {
  Home = "home",
  ResearchHub = "research-hub",
  None = "none",
}

function SidebarNavigation() {
  const [labels] = useLabels("features.sidebar-navigation");
  const router = useRouter();
  let selectedTab = NavigationTab.Home;
  if (router.pathname == path.SEARCH || router.pathname == "/") {
    selectedTab = NavigationTab.Home;
  } else if (router.pathname.includes("/hub")) {
    selectedTab = NavigationTab.ResearchHub;
  } else {
    selectedTab = NavigationTab.None;
  }

  const navigationTabs = [
    {
      id: NavigationTab.Home,
      label: labels["home"],
      icon: "icon-home-converted",
      enabled: true,
      url: "/search",
    },
    {
      id: NavigationTab.ResearchHub,
      label: labels["research-hub"],
      icon: "icon-folder-converted",
      enabled: true,
      url: "/hub",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      {navigationTabs.map(
        (tab) =>
          tab.enabled && (
            <button
              key={tab.id}
              style={{ fontVariantLigatures: "none" }}
              className={classNames(
                "p-3 w-full flex gap-3 items-center",
                "ring-transparent focus:outline-none",
                "hover:bg-bgr-faint text-fg-base sm-md",
                selectedTab === tab.id
                  ? "border-l-4 border-l-fg-base"
                  : "border-l-4 border-l-transparent"
              )}
              disabled={!tab.enabled}
              onClick={() => {
                if (
                  window.location.pathname !== tab.url &&
                  window.location.pathname !== tab.url + "/"
                ) {
                  router.push(tab.url);
                }
              }}
            >
              <i
                className={classNames(
                  "ml-2 mr-2 text-xl",
                  selectedTab === tab.id ? "text-fg-base" : "text-fg-muted"
                )}
              >
                <i className={classNames("w-4 h-4 text-fg-base", tab.icon)} />
              </i>
              <span>{tab.label}</span>
            </button>
          )
      )}
    </div>
  );
}

export default SidebarNavigation;
