import Icon from "components/Icon";
import useLabels from "hooks/useLabels";

type HistoryActionsModalProps = {
  onDelete: () => void;
  onSave: () => void;
};

function HistoryActionsModal({ onDelete, onSave }: HistoryActionsModalProps) {
  const [searchHistoryLabels] = useLabels("screens.sidebar.history");

  return (
    <div className="flex flex-col w-40">
      <button
        onClick={onSave}
        className="flex items-center w-full gap-3 p-3 rounded-lg hover:bg-gray-100"
      >
        <Icon className="w-5 h-5" name="bookmark" />
        <span>{searchHistoryLabels["save-to-list"]}</span>
      </button>
      <button
        onClick={onDelete}
        className="flex items-center w-full gap-3 p-2 rounded-lg hover:bg-red-100 text-red-emphasis"
        data-testid="delete-button"
      >
        <i className="text-xl icon-trash-2 text-red-emphasis" />
        <span>{searchHistoryLabels["delete"]}</span>
      </button>
    </div>
  );
}

export default HistoryActionsModal;
