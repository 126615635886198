import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFilters from "features/FilterDrawer/hooks/useFilters";
import {
  convertDurationToDays,
  getQueryFromFilters,
} from "features/FilterDrawer/utils/queryParams/filterQueryParams";
import { THREADS_QUERY_KEY } from "features/Sidebar/hooks/useThreads";
import { parseThreadResponse } from "features/Thread/types";
import { createThread } from "helpers/api";
import { threadPath } from "helpers/pageUrl";
import useCredits from "hooks/useCredits";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import useProToggle from "hooks/useProToggle";
import useRequireSignIn from "hooks/useRequireSignIn";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { getThreadQueryKey } from "hooks/useThread";
import { useRouter } from "next/router";
import { setIsSearching } from "store/slices/search";

/**
 * `useNewThread` is a custom React hook for creating a new thread.
 * It uses `react-query` for managing API calls and caching, and integrates with Next.js routing for navigation.
 *
 * When creating a thread, first we check if the user is logged in. If not, we redirect them through sign-in to /search/new, which will again use this hook to try to create the thread.
 *
 * If the user is logged in, we create the thread, save the resulting thread in the react-query cache, and redirect to the new thread's page.
 */
export function useNewThread() {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { redirectIfNotSignedIn } = useRequireSignIn();
  const { isProToggleOn } = useProToggle();
  const { isOutOfProAnalysisCredits, isPremium } = useCredits();
  const { appliedFilters } = useFilters();
  const lang = useAppSelector((state) => state.user.settings.lang);
  const dispatch = useAppDispatch();

  const { isIncognitoModeActive } = useIncognitoMode();

  // Mutation to create a new thread
  const mutation = useMutation(
    async ({
      userMessage,
      replaceHistoryOnRedirect,
    }: {
      userMessage: string;
      replaceHistoryOnRedirect?: boolean;
    }) => {
      // API only accepts "day" units for duration, so convert it before passing.
      const filters = convertDurationToDays(appliedFilters);

      // If the user is out of pro analysis credits, we need to disable pro features.
      // This will mostly not occur due to paywalls, but this will prevent a user that is out of credits
      // from using pro features when clicking searches from the history panel when the pro toggle is on.
      const isProEnabled =
        !!isProToggleOn && (!isOutOfProAnalysisCredits || isPremium);

      const threadResponse = await createThread({
        userMessage,
        isProEnabled,
        isIncognito: isIncognitoModeActive,
        filters: getQueryFromFilters(filters),
        lang,
      });
      return { threadResponse, replaceHistoryOnRedirect };
    },
    {
      onSuccess: ({ threadResponse, replaceHistoryOnRedirect }) => {
        // Cache the new thread data for the created thread so that we don't refetch it after redirecting to the thread page.
        queryClient.setQueryData(
          getThreadQueryKey(threadResponse.thread_id),
          parseThreadResponse(threadResponse)
        );

        // Update list of threads in sidebar to reflect new thread
        queryClient.invalidateQueries({
          queryKey: THREADS_QUERY_KEY,
          exact: true,
        });

        // Redirect to the thread page
        const redirect = threadPath(
          threadResponse.thread_id,
          threadResponse.title
        );

        // Used by the "/search/new" page to ensure that after creating a thread and redirecting to it,
        // hitting back in the browser does not go back to /search/new and create a second thread.
        if (replaceHistoryOnRedirect) {
          router.replace(redirect);
        } else {
          router.push(redirect);
        }
      },
      onSettled: () => {
        dispatch(setIsSearching(false));
      },
    }
  );

  const createNewThread = async (
    userMessage: string,
    replaceHistoryOnRedirect = false
  ) => {
    const isSignedIn = redirectIfNotSignedIn();
    if (!isSignedIn) return;

    await mutation.mutateAsync({ userMessage, replaceHistoryOnRedirect });
  };

  return {
    isCreating: mutation.isLoading,
    error: mutation.error as Error | null,
    createNewThread,
  };
}
