import { FeatureFlag, useIsFeatureEnabled } from "features/FeatureGates";
import NewSearchButton from "features/History/components/NewSearchButton";
import SidebarNavigation from "features/History/components/SidebarNavigation";
import NewThreadButton from "features/NewThreadButton";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import { useSidebar } from "hooks/useSidebar";
import History from "../../History";
import { IncognitoModeBanner } from "./IncognitoModeBanner";

function SignedInContent() {
  const { toggleSidebar } = useSidebar();
  const handleOnNavigate = () => {
    toggleSidebar();
  };

  const isThreadsEnabled = useIsFeatureEnabled(FeatureFlag.THREADS);

  const { isIncognitoModeActive } = useIncognitoMode();

  return (
    <div className="h-full overflow-hidden">
      <div className="flex justify-center mb-4">
        {isThreadsEnabled ? <NewThreadButton /> : <NewSearchButton />}
      </div>
      <SidebarNavigation />
      {isIncognitoModeActive && <IncognitoModeBanner />}
      <History onNavigate={handleOnNavigate} />
    </div>
  );
}

export default SignedInContent;
