import { PricingSwitchFlag } from "components/Pricing/PricingSwitch";
import { WEB_URL } from "constants/config";
import { ParsedUrlQuery } from "querystring";

const DETAIL_PAGE_URL_MISSING_SLUG_FALLBACK = "details";

export function searchPagePath(): string {
  return `/search/`;
}

export function searchPageUrl(): string {
  return `${WEB_URL}${searchPagePath()}`;
}

export function orgPageUrl(): string {
  return `${WEB_URL}/organization`;
}

export interface FilterParams {
  // String filters
  study_types?: string;
  domain?: string;
  country?: string;
  // Bool filters
  controlled?: string;
  human?: string;
  open_access?: string;
  oa?: string;
  chat_pdf?: string;
  // Range filters
  cite_min?: string;
  sample_size_max?: string;
  sample_size_min?: string;
  sjr_max?: string;
  sjr_min?: string;
  year_max?: string;
  year_min?: string;
  duration_min?: string;
  duration_max?: string;
  duration_unit?: string;
  exclude_preprints?: string;
}

export type FilterParam = keyof FilterParams;

export interface ResultPageParams extends FilterParams {
  pro?: string;
  lang?: string;
  // Test Params
  cache_off?: string;
}

export interface NewThreadPageParams extends ResultPageParams {}

const addPathQueryParam = (
  path: string,
  queryParamName: string,
  queryParamValue?: string
) => {
  path += `${
    path.includes("?") ? "&" : "?"
  }${queryParamName}=${queryParamValue}`;
  return path;
};

/**
 * Converts a URL string with query parameters into a ParsedUrlQuery object.
 * Example:
 * "/results/?q=write%205%20words%20about%20vaccines&oa=true&cite_min=10"
 * → { q: "write 5 words about vaccines", oa: "true", cite_min: "10" }
 */
export function parseUrlToQuery(url: string): ParsedUrlQuery {
  const queryIndex = url.indexOf("?");
  if (queryIndex === -1) return {}; // No query parameters found

  const queryString = url.substring(queryIndex + 1);
  const urlSearchParams = new URLSearchParams(queryString);
  const parsedQuery: ParsedUrlQuery = {};

  urlSearchParams.forEach((value, key) => {
    // Convert single values to strings and keep arrays as arrays
    if (parsedQuery[key]) {
      parsedQuery[key] = Array.isArray(parsedQuery[key])
        ? [...(parsedQuery[key] as string[]), value]
        : [parsedQuery[key] as string, value];
    } else {
      parsedQuery[key] = value;
    }
  });

  return parsedQuery;
}

// Use the same logic and parameters as when redirecting to the results page, but go to a page that creates a thread and adds a first search.
export function newThreadPagePath(query: string, params?: NewThreadPageParams) {
  return resultsPagePath(query, params, "/search/new/");
}

export function resultsPagePath(
  query: string,
  params?: ResultPageParams,
  pagePath?: string
): string {
  let path = pagePath ?? `/results/`;

  if (query) {
    path = addPathQueryParam(path, "q", encodeURIComponent(query));
  }
  if (params?.pro) {
    path = addPathQueryParam(path, `pro`, params?.pro);
  }
  // String filters
  if (params?.study_types) {
    path = addPathQueryParam(path, `study_types`, params?.study_types);
  }
  if (params?.domain) {
    path = addPathQueryParam(path, "domain", params?.domain);
  }
  if (params?.lang) {
    path = addPathQueryParam(path, "lang", params?.lang);
  }
  if (params?.country) {
    path = addPathQueryParam(path, "country", params?.country);
  }
  // Bool filters
  if (params?.controlled) {
    path = addPathQueryParam(path, "controlled", params?.controlled);
  }
  if (params?.human) {
    path = addPathQueryParam(path, "human", params?.human);
  }
  if (params?.open_access || params?.oa) {
    path = addPathQueryParam(path, "oa", "true");
  }
  if (params?.exclude_preprints) {
    path = addPathQueryParam(
      path,
      "exclude_preprints",
      params?.exclude_preprints
    );
  }
  if (params?.chat_pdf) {
    path = addPathQueryParam(path, "chat_pdf", params?.chat_pdf);
  }
  // Range filters
  if (params?.cite_min) {
    path = addPathQueryParam(path, "cite_min", params?.cite_min);
  }
  if (params?.sample_size_min) {
    path = addPathQueryParam(path, "sample_size_min", params?.sample_size_min);
  }
  if (params?.sample_size_max) {
    path = addPathQueryParam(path, "sample_size_max", params?.sample_size_max);
  }
  if (params?.sjr_min) {
    path = addPathQueryParam(path, "sjr_min", params?.sjr_min);
  }
  if (params?.sjr_max) {
    path = addPathQueryParam(path, "sjr_max", params?.sjr_max);
  }
  if (params?.year_min) {
    path = addPathQueryParam(path, "year_min", params?.year_min);
  }
  if (params?.year_max) {
    path = addPathQueryParam(path, "year_max", params?.year_max);
  }
  if (params?.duration_min) {
    path = addPathQueryParam(path, "duration_min", params?.duration_min);
  }
  if (params?.duration_max) {
    path = addPathQueryParam(path, "duration_max", params?.duration_max);
  }
  if (params?.duration_unit) {
    path = addPathQueryParam(path, "duration_unit", params?.duration_unit);
  }
  //Test Params
  if (params?.cache_off) {
    path = addPathQueryParam(path, "cache_off", params?.cache_off);
  }
  return path;
}

export function resultsPageUrl(
  query: string,
  params?: ResultPageParams
): string {
  return `${WEB_URL}${resultsPagePath(query, params)}`;
}

export function seoQuestionPageUrl(question_id: string): string {
  return `${WEB_URL}/questions/${question_id}/`;
}

export function isSearchPageUrl(url: string): boolean {
  if (url && (url == "/" || url.startsWith("/search"))) {
    return true;
  }
  return false;
}

export function isResultsPageUrl(url: string): boolean {
  if (url && url.startsWith("/results")) {
    return true;
  }
  return false;
}

export function isDetailsPageUrl(url: string): boolean {
  if (url && url.startsWith("/papers/")) {
    return true;
  }
  return false;
}

export function isPapersPageUrl(url: string): boolean {
  if (url && url.startsWith("/papers/")) {
    return true;
  }
  return false;
}

export function isBookmarkListsPageUrl(url: string): boolean {
  if (url && url.startsWith("/lists")) {
    return true;
  }
  return false;
}

export function isSubscriptionPageUrl(url: string): boolean {
  if (url && url.startsWith("/subscription")) {
    return true;
  }
  return false;
}

export function isSignUpOrSignInPage(url: string): boolean {
  return url.startsWith("/sign-up") || url.startsWith("/sign-in");
}

export function detailPagePath(
  slug: string | null = DETAIL_PAGE_URL_MISSING_SLUG_FALLBACK,
  id: string
): string {
  if (!slug) slug = DETAIL_PAGE_URL_MISSING_SLUG_FALLBACK;

  if (hasUploadedPaperSlugFormat(slug)) {
    return `/papers/uploaded/${id}/`;
  }

  return `/papers/${encodeURIComponent(slug)}/${id}/`;
}

/**
 * Checks if a paper URL slug matches the format for an uploaded paper
 * @returns True if the slug matches the uploaded paper format (uploaded-paper-{id}), false otherwise
 */
function hasUploadedPaperSlugFormat(slug: string): boolean {
  return slug.startsWith("uploaded-paper-");
}

export function detailPageUrl(slug: string, id: string): string {
  return `${WEB_URL}${detailPagePath(slug, id)}`;
}

export function pricingPageUrl(
  price_id?: string,
  quantity?: number,
  period?: PricingSwitchFlag
): string {
  let path = `${WEB_URL}/pricing/`;
  let qParams: {
    price_id?: string;
    quantity?: string;
    period?: PricingSwitchFlag;
  } = {};
  if (price_id) {
    qParams["price_id"] = price_id;
  }
  if (quantity) {
    qParams["quantity"] = `${quantity}`;
  }
  if (period) {
    qParams["period"] = period;
  }
  const searchParams = new URLSearchParams(qParams).toString();

  return searchParams ? (path += `?${searchParams}`) : path;
}

export function subscriptionPageUrl(action?: string): string {
  let path = `${WEB_URL}/subscription`;
  if (action) {
    path += `?action=${action}`;
  }
  return path;
}

export function apiManagementPageUrl(): string {
  return `${WEB_URL}/subscription/api/`;
}

export function apiHomePageUrl(): string {
  return `${WEB_URL}/home/api`;
}

export function bookmarkListDetailPagePath(id: string): string {
  return `/lists/${id}/`;
}

const MAX_THREAD_SLUG_LENGTH_CHARS = 50;

export function generateSlug(title: string) {
  // Replace non-alphanumeric characters (excluding spaces) with an empty string
  let slug = title
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s-]/g, "") // Remove special characters except spaces and hyphens
    .trim() // Trim leading/trailing spaces
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen

  // Truncate to the maximum slug length
  slug = slug.substring(0, MAX_THREAD_SLUG_LENGTH_CHARS);

  return slug;
}

export function threadPath(threadId: string, title: string) {
  const slug = generateSlug(title);
  return `/search/${slug}/${threadId}/`;
}

export function threadUrl(thread_id: string, title: string): string {
  return `${WEB_URL}${threadPath(thread_id, title)}`;
}
