import { useStatsigClient } from "@statsig/react-bindings";
import { FeatureFlag } from "../constants";

/**
 * @hook useIsFeatureEnabled
 * @description Hook for checking if the feature is enabled
 * @example
 * const isThreadsEnabled = useIsFeatureEnabled(FeatureFlag.THREADS);
 */
const useIsFeatureEnabled = (feature: FeatureFlag) => {
  const { client } = useStatsigClient();
  return client.checkGate(feature);
};

export default useIsFeatureEnabled;
