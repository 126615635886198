const path = {
  ACCOUNT: "/account",
  INTERNAL_SERVER_ERROR: "/500",
  NEW_THREAD: "/search/new",
  PRICING: "/pricing",
  RESEARCH_HUB_LISTS: "/hub/lists",
  RESEARCH_HUB_UPLOADED: "/hub/uploaded",
  RESULTS: "/results",
  SEARCH: "/search",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  SUBSCRIPTION: "/subscription",
  TOO_MANY_REQUESTS: "/429",
};

export default path;
