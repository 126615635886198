import { useUser } from "@clerk/nextjs";
import {
  StatsigProvider,
  StatsigUser,
  useClientAsyncInit,
} from "@statsig/react-bindings";
import { StatsigSessionReplayPlugin } from "@statsig/session-replay";
import { StatsigAutoCapturePlugin } from "@statsig/web-analytics";
import { STATSIG_CLIENT_API_KEY } from "constants/config";
import { ReactNode, useEffect, useMemo } from "react";
import { setCookie } from "react-use-cookie";

export const STATSIG_STABLE_ID_COOKIE = "__consensus_statsig-stable-id";

type StatsigWrapperProps = {
  children: ReactNode;
};

export default function StatsigWrapper({ children }: StatsigWrapperProps) {
  const { user } = useUser();

  const statsigUser: StatsigUser = useMemo(() => {
    return { userID: user?.id, email: user?.primaryEmailAddress?.emailAddress };
  }, [user?.id]);

  const { client } = useClientAsyncInit(
    STATSIG_CLIENT_API_KEY ?? "", // If key is missing, warnings are logged to the console.
    statsigUser,
    {
      plugins: [
        new StatsigAutoCapturePlugin(),
        new StatsigSessionReplayPlugin(),
      ],
    }
  );

  // Save user's statsig stable id in a cookie, so that we can read it server-side and enable
  // feature flags for them even if they are not logged in.
  useEffect(() => {
    if (!client) return;

    const stableID = client.getContext()?.stableID;
    if (stableID) {
      setCookie(STATSIG_STABLE_ID_COOKIE, stableID);
    }
  }, [client]);

  // The client gets configured before the user is loaded, so update the user once available or if the user changes.
  useEffect(() => {
    if (statsigUser.userID) client.updateUserSync(statsigUser);
  }, [client, statsigUser]);

  return (
    <StatsigProvider client={client} loadingComponent={<div></div>}>
      {children}
    </StatsigProvider>
  );
}
