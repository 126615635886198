import { useAuth, useUser } from "@clerk/nextjs";
import { UserResource } from "@clerk/types";
import { useQuery } from "@tanstack/react-query";
import { ButtonVariant, CoButton } from "components/CoButton";
import { Combobox, OptionObj, OptionType } from "components/Combobox";
import StackedEmptyModal from "components/Modal/StackedEmptyModal";
import PricingSwitch, {
  PricingSwitchFlag,
} from "components/Pricing/PricingSwitch";
import Tag from "components/Tag";
import {
  STUDENT_DISCOUNT_PERCENT,
  STUDENT_TRIAL_PERIOD_DAYS,
} from "constants/config";
import path from "constants/path";
import { SubscriptionPlan } from "enums/subscription-plans";
import { getPricingTextAPI } from "helpers/api";
import { addDaysToCurrentDate, formatDateString } from "helpers/date";
import { getUserStudentEmail } from "helpers/email";
import { formatCurrency } from "helpers/format";
import { pricingPageUrl } from "helpers/pageUrl";
import {
  getAnnualCost,
  getGraduationYearsOptions,
  getStudentPricingDiscountAttr,
} from "helpers/pricing";
import { getAnnualSaving, getPlanBasePrices } from "helpers/products";
import { getSubscriptionProducts } from "helpers/subscription";
import useLabels from "hooks/useLabels";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { setPricingText, setProducts } from "store/slices/subscription";
import { useSubscription } from "../SubscriptionProvider/SubscriptionProvider";
import { usePremiumFeatureModal } from "./usePremiumFeatureModal";

export const PREMIUM_FEATURE_MODAL_ID = "premium-feature-modal";
const graduationYearsOptions = getGraduationYearsOptions();
/**
 * @component PremiumFeatureModal
 * @description Component for premium feature modal
 * @example
 * return (
 *   <PremiumFeatureModal />
 * )
 */
function PremiumFeatureModal() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { isSignedIn, isLoaded } = useAuth();
  const { user } = useUser();
  const {
    currentFeature,
    isPremiumFeatureModalOpen,
    forceClosePremiumFeatureModal,
    closeAllModals,
    onClosePremiumFeatureModal,
  } = usePremiumFeatureModal();

  const [modalLabels, pricingLabels, featureLabels] = useLabels(
    "premium-feature-modal",
    "screens.pricing",
    "credits.features"
  );
  const [activeFlag, setActiveFlag] = useState(PricingSwitchFlag.ANNUALLY);
  const products = useAppSelector((state) => state.subscription.products);
  const { premium } = useAppSelector((state) => state.subscription.pricingText);
  const features_list: string[] = premium?.features ?? [];
  const { redirectToCheckout } = useSubscription();
  const [graduationYear, setGraduationYear] = useState(
    graduationYearsOptions[0]
  );

  const isAnnualSelected = activeFlag === PricingSwitchFlag.ANNUALLY;

  useQuery(["get-pricing-text"], async () => {
    try {
      const data = await getPricingTextAPI();
      dispatch(setPricingText(data));
      return data;
    } catch (error) {}
  });

  useQuery(
    ["subscription-products-query"],
    async () => {
      const sortedProducts = await getSubscriptionProducts();
      dispatch(setProducts(sortedProducts));
      return sortedProducts;
    },
    {
      enabled: isPremiumFeatureModalOpen,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  let { baseMonthlyPrice, baseYearlyPrice } = getPlanBasePrices(products);

  const studentEmail = getUserStudentEmail(user as unknown as UserResource);
  const applyStudentDiscount = !!studentEmail;

  const handleClickUpgrade = () => {
    closeAllModals();

    let priceId;

    if (activeFlag == PricingSwitchFlag.MONTHLY && baseMonthlyPrice) {
      priceId = baseMonthlyPrice.id;
    }

    if (isAnnualSelected && baseYearlyPrice) {
      priceId = baseYearlyPrice.id;
    }

    if (!isLoaded) return;

    if (!isSignedIn) {
      router.push(
        `${path.SIGN_UP}#/?redirect_url=${encodeURIComponent(
          `${pricingPageUrl(priceId)}`
        )}`
      );
      return;
    }

    if (applyStudentDiscount) {
      const discountAttrs = getStudentPricingDiscountAttr(
        (graduationYear as OptionObj).value
      );
      redirectToCheckout({
        price: priceId,
        discountId: discountAttrs.discountId,
        trialPeriodDays: STUDENT_TRIAL_PERIOD_DAYS,
      });
    } else {
      redirectToCheckout({
        price: priceId,
      });
    }
  };

  const handleClickSeeOtherPlans = () => {
    closeAllModals();
    router.push(pricingPageUrl());
  };

  const getRealCost = (price: any) => {
    if (applyStudentDiscount) {
      return (price.unit_amount * (100 - STUDENT_DISCOUNT_PERCENT)) / 100;
    }
    return price.unit_amount;
  };

  const handleChangeGraduationYear = (item: OptionType) => {
    setGraduationYear(item);
  };

  const title =
    currentFeature !== undefined
      ? modalLabels["title-feature"].replace(
          "{FEATURE}",
          featureLabels[currentFeature]["credit-type"]
        )
      : modalLabels["title"];

  return (
    <StackedEmptyModal
      modalId={PREMIUM_FEATURE_MODAL_ID}
      onClose={onClosePremiumFeatureModal}
    >
      <div className="bg-white rounded-t-xl w-full sm:rounded-b-xl sm:w-[500px] max-h-[95vh] border-box sm:max-h-[600px] overflow-hidden flex flex-col justify-items-stretch items-stretch border sm:border-0">
        <div className="py-3 pl-4 pr-1.5 border-b relative">
          <div className="flex items-center">
            <h1 className="flex-1 ml-10 text-center lg-bold text-fg-base">
              {title}
            </h1>
            <CoButton
              variant={ButtonVariant.Flat}
              size="sm"
              onClick={() => forceClosePremiumFeatureModal()}
              icon="icon-x"
              data-testid="close-button"
            />
          </div>
          <div className="flex justify-center mt-2">
            <PricingSwitch
              onChange={(currentFlag) => {
                setActiveFlag(currentFlag);
              }}
              initialFlag={PricingSwitchFlag.ANNUALLY}
            />
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <div className="m-4">
            {applyStudentDiscount && (
              <div className="flex items-center gap-4 px-4 py-3 mb-4 bg-white border rounded-xl border-border-base">
                <span>
                  <i className="text-2xl icon-ticket text-green-base" />
                </span>
                <div>
                  <h3 className="sm-md">
                    {pricingLabels["student-discount-applied"]}
                  </h3>
                  <p className="sm-normal text-fg-muted">
                    {pricingLabels["student-discount-desc"].replace(
                      "{EMAIL_DOMAIN}",
                      `@${studentEmail.split("@").pop()}`
                    )}
                  </p>
                </div>
              </div>
            )}
            <div
              style={{ backgroundSize: "initial" }}
              className="flex flex-col gap-4 p-4 mt-4 bg-contain border rounded-xl bg-[#F7FCFF] border-accent-subtle"
            >
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <img
                      src={`/icons/premium.svg`}
                      alt="premium"
                      className="w-5 h-5"
                    />
                    <h3 className="base-bold">{modalLabels["premium"]}</h3>
                  </div>
                  {(isAnnualSelected ||
                    (!isAnnualSelected && applyStudentDiscount)) &&
                    products.length !== 0 && (
                      <div className="flex gap-2">
                        {applyStudentDiscount && (
                          <span className="inline-flex items-center px-2 text-xs rounded-lg bg-bgr-subtle text-fg-muted">
                            {pricingLabels["student-text"]}
                          </span>
                        )}
                        <Tag
                          className="bg-green-faint text-green-emphasis"
                          size="sm"
                        >
                          {applyStudentDiscount && (
                            <i className="icon-ticket text-[16px] text-green-base" />
                          )}
                          {pricingLabels["saving"].replace(
                            "{SAVINGS}",
                            formatCurrency(
                              Math.ceil(
                                getAnnualSaving(
                                  SubscriptionPlan.PREMIUM,
                                  products,
                                  isAnnualSelected,
                                  applyStudentDiscount
                                ) / 100
                              )
                            )
                          )}
                        </Tag>
                      </div>
                    )}
                </div>
                <p className="sm-normal text-fg-muted">
                  {modalLabels["premium-description"]}
                </p>
              </div>
              <div>
                {activeFlag == PricingSwitchFlag.MONTHLY && baseMonthlyPrice && (
                  <div>
                    <span className="lg-bold text-fg-base">
                      {formatCurrency(getRealCost(baseMonthlyPrice) / 100)}
                    </span>
                    <span className="ml-1 text-fg-muted">
                      {modalLabels["per-month"]}
                    </span>
                  </div>
                )}

                {isAnnualSelected && baseYearlyPrice && (
                  <div>
                    <span className="lg-bold text-fg-base">
                      {formatCurrency(getRealCost(baseYearlyPrice) / 12 / 100)}
                    </span>
                    <span className="ml-1 text-fg-muted">
                      {modalLabels["per-month"]}
                    </span>
                    {isAnnualSelected && (
                      <p className="text-fg-muted sm-normal">
                        {applyStudentDiscount
                          ? pricingLabels["student-billed-annually"]
                              .replace(
                                "{BILLED}",
                                formatCurrency(
                                  Math.ceil(
                                    getAnnualCost(
                                      baseYearlyPrice,
                                      STUDENT_DISCOUNT_PERCENT
                                    ) / 100
                                  )
                                )
                              )
                              .replace(
                                "{DATE}",
                                formatDateString(
                                  addDaysToCurrentDate(
                                    STUDENT_TRIAL_PERIOD_DAYS
                                  )
                                )
                              )
                          : pricingLabels["billed-annually"].replace(
                              "{BILLED}",
                              formatCurrency(
                                Math.ceil(getAnnualCost(baseYearlyPrice) / 100)
                              )
                            )}
                      </p>
                    )}
                  </div>
                )}
                {!isAnnualSelected && applyStudentDiscount && (
                  <p className="text-fg-muted tiny-normal">
                    {pricingLabels["student-billed-monthly"].replace(
                      "{DATE}",
                      formatDateString(
                        addDaysToCurrentDate(STUDENT_TRIAL_PERIOD_DAYS)
                      )
                    )}
                  </p>
                )}
              </div>
              <CoButton
                variant={ButtonVariant.Primary}
                size="sm"
                onClick={handleClickUpgrade}
                className="w-full"
                label={
                  applyStudentDiscount
                    ? pricingLabels["premium-student-button"]
                    : modalLabels["upgrade"]
                }
              />
              {applyStudentDiscount && (
                <section>
                  <Combobox
                    options={graduationYearsOptions}
                    inputFieldClassName="h-[50px]"
                    defaultValue={graduationYear}
                    onChange={handleChangeGraduationYear}
                    data-testid="gradu-combobox"
                  />
                </section>
              )}
              <div className="flex flex-wrap feature-list gap-y-2">
                {features_list.map((feature: any) => (
                  <div
                    key={`feature-${feature}`}
                    className={`flex w-full feture-list-item ${
                      feature.length < 37 ? "items-center" : "items-start"
                    } gap-x-2`}
                  >
                    <i className="w-5 h-5 text-green-emphasis icon-check" />
                    <span className="flex-1 sm-normal text-fg-base">
                      {feature}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-4 p-4 my-4 border rounded-xl">
              <span>
                <i className="text-2xl icon-ticket text-green-base" />
              </span>
              <div className="flex-1">
                <h3 className="sm-md">{modalLabels["are-you-student"]}</h3>
                <span className="text-fg-muted sm-normal">
                  {modalLabels["student-offer-desc"]}
                </span>
              </div>
              <CoButton
                variant={ButtonVariant.Tertiary}
                label={modalLabels["claim"]}
                onClick={handleClickSeeOtherPlans}
                size="sm"
              />
            </div>
            <div className="text-center">
              <CoButton
                onClick={handleClickSeeOtherPlans}
                variant={ButtonVariant.Flat}
                label={modalLabels["view-more-plans"]}
              />
            </div>
          </div>
        </div>
      </div>
    </StackedEmptyModal>
  );
}

export default PremiumFeatureModal;
